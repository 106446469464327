.fit {
  min-height: calc(100vh - 88px);
}

.rte-content {
  p,
  ul,
  li,
  a {
    margin-top: 0;
  }
  b,
  strong {
    font-weight: 700;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
/*  3.4 - Rich Text Editor Content
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.rte-content table {
  width: 100%;
}

.rte-content h1 {
  font-size: 24px;
}

.rte-content h2 {
  font-size: 20px;
}

.rte-content h3 {
  font-size: 18px;
  margin: 0 0 18px;
}

.rte-content h4 {
  font-size: 16px;
  margin: 0 0 16px;
}

.rte-content h5 {
  font-size: 14px;
  margin: 0 0 14px;
}

.rte-content h6 {
  font-size: 12px;
  margin: 0 0 12px;
}

.rte-content ul,
.rte-content ol,
.rte-content table,
.rte-content blockquote,
.rte-content p {
  margin-bottom: 20px;
}

.rte-content ul {
  padding-left: 20px;
}

.rte-content li {
  margin-top: 0;
  margin-bottom: 10px;
}

.rte-content img {
  margin: 10px 0;
  width: 100%;
}

.rte-content ol {
  counter-reset: item;
  padding: 0;
}

.rte-content ol li {
  display: block;
}

.rte-content ol li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
}

.rte-content ol ol {
  padding-left: 20px;
  margin-top: 10px;
}

.rte-content a {
  text-decoration: underline;
  color: #474747 !important;
}

.collection-seo div,
.collection-seo p {
  display: inline;
}

.collection-seo a {
  font-size: 1em !important;
}
