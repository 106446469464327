.hide-slider {
  display: none;
}

.slick-dots li button:before {
  content: '' !important;
  height: 4px !important;
  width: 100% !important;
  opacity: 1 !important;
}
.slick-dots li {
  width: 96px !important;
  margin: 0 !important;
  background-color: #f4f4f4;
  height: 4px;
}
.slick-dots li:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.slick-dots li:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.slick-arrow {
  width: 32px !important;
  height: 32px !important;
}
.slick-prev,
.slick-next {
  background: #f6f5f4 !important;
  color: black !important;
}
.slick-prev:before {
  color: black !important;
  width: 7px !important;
  height: 7px !important;
  border-top: 1px solid #464544 !important;
  border-left: 1px solid #464544 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  content: '' !important;
  display: inline-block !important;
  margin: 0 5px;
  transform: rotate(-45deg) !important;
}
.slick-next:before {
  color: black !important;
  width: 7px !important;
  height: 7px !important;
  border-top: 1px solid #464544 !important;
  border-left: 1px solid #464544 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  content: '' !important;
  display: inline-block !important;
  margin: 0 5px;
  transform: rotate(135deg) !important;
}
.slick-next:hover::before,
.slick-prev:hover::before {
  border-color: black !important;
  color: black !important;
}
.slick-prev:after {
  right: -40%;
  content: '';
  width: 1px;
  height: 24px;
  top: -1px;
  position: absolute;
}

.slick-prev {
  left: auto !important;
  top: -50px !important;
  right: 44px;
  margin-top: 1px;
  margin-right: 8px;
  padding: 11px 9px;
  border-radius: 50%;
}
.slick-next {
  top: -50px !important;
  right: 15px !important;
  margin-top: 1px;
  /*padding: 11px 9px;*/
  border-radius: 50%;
}
.slick-dots li.slick-active button:before {
  background-color: #474747;
  border-radius: 2px;
}
.slick-dots {
  position: relative !important;
  margin-top: 32px;
  bottom: 0 !important;
  display: flex !important;
  justify-content: center;
  height: 4px;
}

@media screen and (max-width: 425px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.slick-slide > div {
  margin: 0 8px;
}
.slick-list {
  margin: 0 -8px;
}

.brandList .slick-list {
  margin: 0 -4px;
}

.brandList .slick-slide > div {
  margin: 0 4px;
}

.product-slick .slick-list {
  margin: 0 -16px;
}

.product-slick .slick-slide > div {
  margin: 0 16px;
}

.brandsCarousel .slick-slider .slick-track {
  display: flex !important;
  align-items: center !important;
}

.brandsCarousel .slick-slide img {
  margin: auto;
}

@media (max-width: 425px) {
  .product-slick .slick-list {
    margin: 0 -8px;
  }

  .product-slick .slick-slide > div {
    margin: 0 8px;
  }
}

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
