@tailwind base;
@import './base.css';
@import './nprogress.css';
@import './react-slick.css';
@tailwind components;
@import './components.css';

@tailwind utilities;

/* breakpoints map
xl: desktop
lg: laptop
md: tablet
sm: mobile
/* tailwind-scaling: https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale */

body {
  @apply body-1 text-brand-dark-grey;
}

@layer utilities {
  @variants responsive {
    .wrapper {
      @apply w-full xl:max-w-desktop mx-auto px-4 lg:px-16;
    }

    .heading-1,
    .wysiwyg h1 {
      @apply font-roboto font-normal text-h1;
    }

    .heading-2,
    .wysiwyg h2 {
      @apply font-roboto font-normal text-h2;
    }

    .heading-3,
    .wysiwyg h3 {
      @apply font-roboto font-normal text-h3;
    }

    .heading-4,
    .wysiwyg h4 {
      @apply font-roboto font-normal text-h4;
    }

    .heading-mobile-1 {
      @apply font-roboto font-normal text-mobileh1;
    }

    .heading-mobile-2 {
      @apply font-roboto font-normal text-mobileh2;
    }

    .heading-mobile-3 {
      @apply font-roboto font-normal text-mobileh3;
    }

    .heading-mobile-4 {
      @apply font-roboto font-normal text-mobileh4;
    }

    .body-1 {
      @apply font-roboto font-normal text-body1;
    }

    .body-1-bold {
      @apply font-roboto font-bold text-body1;
    }

    .body-2 {
      @apply font-roboto font-normal text-body2;
    }

    .body-2-bold {
      @apply font-roboto font-bold text-body2;
    }

    .body-3 {
      @apply font-roboto font-light text-body3;
    }

    .body-3-bold {
      @apply font-roboto font-bold text-body3;
    }

    .label {
      @apply font-roboto font-normal text-label;
    }

    .label-bold {
      @apply font-roboto font-bold text-label;
    }

    .label-large {
      @apply font-roboto font-normal text-labelLarge;
    }

    .label-large-bold {
      @apply font-roboto font-bold text-labelLarge;
    }
  }
}

/*hotjar default feedback button styling starts*/

._hj_feedback_container ._hj-G09L\+__MinimizedWidgetMiddle__container {
  display: none !important;
}

/*hotjar default feedback button styling ends*/
