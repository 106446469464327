:root {
  /*Brand colors*/
  --brand-chrome: rgba(70, 69, 68, 100);
  --brand-chrome: rgba(70, 69, 68, 100);
  --brand-polar-white: rgba(246, 245, 244, 100);
  --brand-dark-grey: #474747;
  /*Text colors*/
  --text-light: rgba(255, 255, 255, 100);
  --text-subdued: rgba(71, 71, 71, 100);
  --text-dark: rgba(39, 39, 39, 100);
  --text-disabled: rgba(147, 147, 147, 100);
  /*UI colors*/
  --ui-white: rgba(255, 255, 255, 100);
  --ui-lightest-grey: rgba(250, 250, 250, 100);
  --ui-light-grey: rgba(244, 244, 244, 100);
  --ui-grey-25: rgba(201, 201, 201, 100);
  --ui-grey-50: rgba(147, 147, 147, 100);
  --ui-dark-grey: rgba(71, 71, 71, 100);
  --ui-darkest-grey: rgba(39, 39, 39, 100);
  /*Icon Colors*/
  --icon-white: rgba(255, 255, 255, 100);
  --icon-chrome: rgba(70, 69, 68, 100);
  /*Message colors*/
  --message-info: rgba(0, 145, 255, 100);
  --message-warning: rgba(247, 181, 0, 100);
  --message-error: rgba(253, 3, 0, 100);
  --message-success: rgba(126, 211, 33, 100);
  --message-info-text: rgba(0, 109, 191, 100);
  --message-warning-text: rgba(196, 144, 0, 100);
  --message-error-text: rgba(149, 8, 16, 100);
  --message-success-text: rgba(80, 149, 8, 100);
  /*Overlay colors*/
  --overlay-light: rgba(255, 255, 255, 20);
  --overlay-dark: rgba(70, 69, 68, 40);
  /*Swatch colors*/
  --swatch-gold: rgba(211, 186, 134, 100);
  --swatch-sliver: rgba(206, 206, 206, 100);
  --swatch-rose-gold: rgba(225, 184, 159, 100);

  --primary: #ffffff;
  --primary-2: #f1f3f5;
  --secondary: #000000;
  --secondary-2: #111;
  --selection: var(--cyan);
  --text-base: #000000;
  --text-primary: #000000;
  --text-secondary: white;
  --colour-polar-white: #F6F5F4;
  --hover: rgba(0, 0, 0, 0.075);
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);
  --cyan: #22b8cf;
  --green: #37b679;
  --red: #da3c3c;
  --purple: #f81ce5;
  --blue: #0070f3;
  --pink: #ff0080;
  --pink-light: #ff379c;
  --magenta: #eb367f;
  --violet: #7928ca;
  --violet-dark: #4c2889;
  --accent-0: #fff;
  --accent-1: #fafafa;
  --accent-2: #eaeaea;
  --accent-3: #999999;
  --accent-4: #888888;
  --accent-5: #666666;
  --accent-6: #464544;
  --accent-7: #272727;
  --accent-8: #111111;
  --accent-9: #000;
  --font-sans: Roboto, -apple-system, system-ui, BlinkMacSystemFont,
    'Helvetica Neue', 'Helvetica', sans-serif;
}

/*[data-theme='dark'] {*/
/*  --primary: #000000;*/
/*  --primary-2: #111;*/
/*  --secondary: #ffffff;*/
/*  --secondary-2: #f1f3f5;*/
/*  --hover: rgba(255, 255, 255, 0.075);*/
/*  --hover-1: rgba(255, 255, 255, 0.15);*/
/*  --hover-2: rgba(255, 255, 255, 0.25);*/
/*  --selection: var(--purple);*/
/*  --text-base: white;*/
/*  --text-primary: white;*/
/*  --text-secondary: black;*/
/*  --accent-9: #fff;*/
/*  --accent-8: #fafafa;*/
/*  --accent-7: #eaeaea;*/
/*  --accent-6: #999999;*/
/*  --accent-5: #888888;*/
/*  --accent-4: #666666;*/
/*  --accent-3: #444444;*/
/*  --accent-2: #333333;*/
/*  --accent-1: #111111;*/
/*  --accent-0: #000;*/
/*}*/

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-primary);
  overscroll-behavior-x: none;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
